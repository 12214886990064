<template>
  <modal
    name="modal-cadastro-callback"
    id="id-modal-cadastro-callback"
    scrollable
    :width="modalSize"
    height="auto"
  >
    <div class="cadastro-callback-container">
      <h2 class="title text-center">Cadastrar Callback</h2>
      <br />
      <br />

      <!-- these will be the fields -->
      <div class="flexer-container">
        <div class="form-group w-100">
          <label for="titulo">Selecione a Campanha</label>
          <pv-dropdown
            class="form-control w-100"
            optionLabel="text"
            optionValue="value"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            :options="campanhas"
            placeholder="Nenhum selecionado"
            v-model="callbackObj.modelo_campanha_id"
            appendTo="id-modal-cadastro-callback"
          ></pv-dropdown>
        </div>

        <div class="form-group w-100">
          <label for="titulo">Selecione o Fluxo</label>
          <pv-dropdown
            class="form-control w-100"
            optionLabel="text"
            optionValue="value"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            :options="fluxos"
            placeholder="Nenhum selecionado"
            v-model="callbackObj.chatbot_fluxo_id"
            appendTo="id-modal-cadastro-callback"
          ></pv-dropdown>
        </div>
      </div>
      <div class="btn-salvar-callback">
        <button class="btn w-100 mt-5 btn-success" @click="cadastrarCallback">
          Salvar
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    callbacks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      callbackObj: {
        chatbot_fluxo_id: null,
        modelo_campanha_id: null
      },
      callbackCadastrar: {
        callbackFluxo: "",
        callbackTitulo: "",
        callbackOrdem: 1,
        callbackAtivo: false
      },
      campanhas: [],
      callbacksChatbot: [],
      callbackConfiguracao: [],
      porPagina: 10,
      paginaAtual: 1,
      fluxos: [],
      campos: [
        { key: "chatbot_callback_item_id", label: "ID" },
        { key: "cfl_titulo", label: "Nome" },
        { key: "descricao", label: "Descrição" },
        { key: "data_criacao", label: "Data de Criação" },
        { key: "data_atualizacao", label: "Data de Atualização" },
        { key: "acoes", label: "Ações" }
      ]
    };
  },
  methods: {
    async pegarCallbacksChatbot() {
      const response = await ApiService.get(API_LINKS.chatbot_callback);

      return response.data.callback;
    },
    pegarCampanhas() {
      ApiService.get(API_LINKS.campanha).then((response) => {
        this.campanhas = response.data.campanhas
          .sort((a, b) => {
            return a.mdc_nome > b.mdc_nome ? 1 : -1;
          })
          .map((campanha) => {
            return {
              value: campanha.modelo_campanha_id,
              text: campanha.mdc_nome
            };
          });
      });
    },
    cadastrarCallback() {
      const objCallback = { callback: this.callbackObj };

      ApiService.post(API_LINKS.chatbot_callback, objCallback).then(
        (response) => {
          this.$emit("aoCadastrarCallback");
        }
      );
    },
    pegarFluxos() {
      ApiService.get(API_LINKS.chatbot_listar_fluxos)
        .then((response) => {
          this.fluxos = response.data.fluxos
            .sort((a, b) => {
              return a.cfl_titulo > b.cfl_titulo ? 1 : -1;
            })
            .map((fluxo) => {
              return {
                value: fluxo.chatbot_fluxo_id,
                text: fluxo.cfl_titulo
              };
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    // this.pegarCallbacksChatbot();
    this.pegarFluxos();
    this.pegarCampanhas();
  },
  computed: {
    modalSize() {
      if (window.screen.width > 900) return "600";
      return "400";
    }
  }
};
</script>

<style lang="scss" scoped>
.cadastro-callback-container {
  padding: 24px !important;
  .flexer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
  }
}
</style>
